<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
    <el-dialog
      :visible.sync="showDialog"
      :title="modalTitle"
      :modal-append-to-body="false"
      style="text-align:left;"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
  <div class="user-panel" v-loading="loading">
    <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
      <el-form-item label="访问路径" prop="path">
        <el-input v-model="formModel.path" placeholder="请输入访问路径" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item label="访问方式" prop="method">
        <el-select v-model="formModel.method" placeholder="请选择访问方式" style="width:300px">
          <el-option label="get" value="get"></el-option>
          <el-option label="post" value="post"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="简介" prop="summary">
        <el-input v-model="formModel.summary" placeholder="请输入简介" style="width:300px"></el-input>
      </el-form-item>
    </el-form>
  </div>
  <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
</template>
<script>
import Constant from "@/constant";
import permissionApi from "@/api/sys/permission";

export default {
  props: ["permId","modalTitle"],
  data() {
    return {
      loading: false,
      submitting: false,
      showDialog: true,
      formModel:{},
      ruleValidate: {
        path: [
          { required: true, message: "访问路径不能为空", trigger: "blur" }
        ],
        method: [
          { required: true, message: "访问方式不能为空", trigger: "blur" }
        ],
        summary: [{ required: true, message: "简介不能为空", trigger: "blur" }]
      }
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close",false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return permissionApi.add(self.formModel);
            } else {
              return permissionApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close",true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close",false);
            }
          });
        } else {
          this.$emit("error");
        }
      });
    }
  },
  mounted: function() {
    var self = this;
    self.loading = true;
    
    (function(){
      if(self.permId==null || self.permId.length==0){
        return permissionApi.create();
      }
      else{
        return permissionApi.edit(self.permId);
      }
    })().then(response => {
      var jsonData = response.data;
      self.loading = false;

      if (jsonData.result) {
        self.formModel = jsonData.data;
      } else {
        self.$message.error(jsonData.message + "");
      }
    })
    .catch(error => {
      self.$message.error(error + "");
      self.loading = false;
    });
  }
};
</script>