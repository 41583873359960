<template>
    <el-dialog
      :visible.sync="showDialog"
      title="接口导入"
      :modal-append-to-body="false"
      style="text-align:left;"
      width="800px"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
  <div class="permission-import">
    <el-transfer
      v-model="relatedPerms"
      :data="apiList"
      v-loading="loading"
      filterable
      :filter-method="filterMethod"
      filter-placeholder="请输入关键字"
      :props="{key: 'id',label: 'description'}"
      :titles="['未导入接口','已导入接口']"
    ></el-transfer>
  </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
</template>
<script>
import backendApi from "@/api/sys/backend";
import permissionApi from "@/api/sys/permission";

export default {
  props: ["permId"],
  data() {
    return {
      showDialog: true,
      apiList: [],
      relatedPerms: [],
      loading: false,
      submitting: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      self.submitting = true;

      var dataList = this.apiList.filter(item => {
          return self.relatedPerms.indexOf(item.id)>=0;
      }).map(item=>{
        return {
          method: item.method,
          path: item.path,
          summary: item.summary
        }
      });

      permissionApi.batchImport(dataList).then(function(response) {
        var jsonData = response.data;
        self.submitting = false;

        if (jsonData.result) {
          self.$message({
            message: "保存成功!",
            type: "success"
          });

          self.$emit("close", true);
        } else {
          self.$message({
            message: jsonData.message + "",
            type: "warning"
          });

          self.$emit("close", false);
        }
      });
    },
    filterMethod(query, item) {
      return item.description.toLowerCase().indexOf(query) > -1;
    }
  },
  mounted() {
    var self = this;
    self.loading = true;

    backendApi.selectAll().then(response => {
      var jsonData = eval(response.data);

      for (var path in jsonData.paths) {
        for (var method in jsonData.paths[path]) {
          var summary = jsonData.paths[path][method].summary;

          self.apiList.push({
            id: method + " " + path,
            description: method + " " + path + " " + summary,
            method,
            path,
            summary
          });
        }
      }

      return permissionApi.selectAll();
    }).then(response=>{
      var jsonData = response.data;

      self.relatedPerms = jsonData.data.map(item => {
        return item.method + " " + item.path;
      });

      self.loading = false;
    });
  }
};
</script>
<style lang="scss">
.permission-import {

  .el-transfer-panel {
    border: 1px solid #ebeef5;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 320px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }

}
</style>
