<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/sys/permission">接口权限管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="访问路径" prop="path">
        <el-input type="text" size="mini" v-model="queryModel.path"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-document-add"
        @click="handleImport"
      >导入</el-button>
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      border
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="path" sort-by="path_" label="访问路径" sortable="custom" width="210"></el-table-column>
      <el-table-column prop="method" sort-by="method_" label="访问方式" sortable="custom" width="150"></el-table-column>
      <el-table-column prop="summary" sort-by="summary_" label="简介" sortable="custom" width="180"></el-table-column>
      <el-table-column
        prop="createTime"
        sort-by="create_time"
        label="创建时间"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        sort-by="update_time"
        label="更新时间"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
      <permission-detail
        v-if="showDetailModal"
        @close="onDetailModalClose"
        :modalTitle="modalTitle"
        :permId="permId"
      ></permission-detail>

      <permission-import
        v-if="showImportModal"
        @close="onImportModalClose"
        :permId="permId"
      ></permission-import>
  </div>
</template>
<script>
import Constant from "@/constant";
import PermissionImport from "./permission-import";
import PermissionDetail from "./permission-detail";
import permissionApi from "@/api/sys/permission";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: 'SysPermissionList',
  data() {
    var self = this;

    return {
      queryModel: {
        path: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      permId:"",
      modalTitle: "",
      showDetailModal: false,
      showImportModal: false
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("path", self.queryModel.path);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      permissionApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data;

          if(jsonData.result){
            var pageInfo = jsonData.data;

            self.tableData = pageInfo.data;
            self.totalPages = pageInfo.totalPages;
            self.totalElements = pageInfo.recordsTotal;
          }
          else {
            self.$message({
                message: jsonData.message + "",
                type: "warning"
              });
          }
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      var self = this;
      
      self.modalTitle = "新增";
      self.permId = "";
      self.showDetailModal = true;
    },
    handleEdit(record) {
      var self = this;

      self.modalTitle = "编辑";
      self.permId = record.id;
      self.showDetailModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          permissionApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        permissionApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    handleImport() {
      // 读取所有菜单
      var self = this;

      self.showImportModal = true;
    },
    onDetailModalClose(refreshed){
      this.showDetailModal = false;

      if(refreshed){
        this.changePage(this.pageIndex);
      }
    },
    onImportModalClose(refreshed){
      this.showImportModal = false;

      if(refreshed){
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    this.changePage(1);
  },
  components: {
    "permission-detail": PermissionDetail,
    "permission-import": PermissionImport
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  padding: 10px;
  text-align: left;
}
</style>