import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/sys/permission/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/sys/permission/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/sys/permission/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/sys/permission/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function selectAll(){
  return request.get(constant.serverUrl + "/sys/permission/selectAll");
}

function batchImport(dataList){
  return request.post(constant.serverUrl + "/sys/permission/batchImport", dataList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/sys/permission/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/sys/permission/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/sys/permission/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function hasPermission(method,path){
  var formData = new FormData();
  formData.append("method",method);
  formData.append("path",path);

  return request.post(constant.serverUrl + "/sys/permission/hasPermission", formData);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,selectAll,batchImport,hasPermission
}